.gameCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.buttonFormLanParty {
    border-radius: 10px;
    width: 8rem;
    height: 4vh;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    background-color: #1A9A32;
}

.buttonFormLanParty:hover {
    cursor: pointer;
    background-color: #35ff5a;
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
}

.buttonGreyedFormLanParty {
    border-radius: 10px;
    width: 8vw;
    height: 4vh;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    color: #1E1E1E;
}

.gameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
}

.imgLan {
    width: 90%;
    border-radius: 1rem;
    border-style: solid;
    margin: 1rem;
    border-width: 6px;
    border-color: #1A9A32;
}

.imgLanNeAles {
    width: 90%;
    border-radius: 1rem;
    border-style: solid;
    margin: 1rem;
    border-width: 6px;
    filter: brightness(50%);
    border-color: #1A9A32;

}

.imgLanAles {
    width: 90%;
    border-radius: 1rem;
    border-style: solid;
    margin: 1rem;
    border-width: 6px;
    color: #FFAD4A;
}

@media(max-width: 1270px)
{
    .gameContainer {
        flex-direction: column;
    }

    .gameContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70vw;
    }

    .buttonFormLanParty {
        width: 60vw;
        height: 6vh;
    }
    
    .buttonFormLanParty:hover {
        width: 60vw;
        height: 6vh;
    }
    
    .buttonGreyedFormLanParty {
        width: 60vw;
        height: 6vh;
    }
}