.headerLan{
    font-size: 36px;
    text-align: center;
    color: black;
}

.announcementLan{
    font-size: 18px;
    margin: 10px 0;
    font-weight: 600;
    text-align: center;
    color: black;
}
.lan {
    border-style: solid;
    border-width: 0.8rem;
    border-color: #060606ef;
    padding: 3rem 2.5rem;
}

.countdownContainer p{
    text-align: center;
    color: black;
}

.timerDays{
    font-size: 32px;
    font-weight: bold;
    color: black;
}

.timerHours{
    font-size: 26px;
    font-weight: bolder;
    color: black;
}

.timerMinutes{
    font-size: 20px;
    font-weight: 600;
    color: black;
}