.giveawayInfoContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--pistachio-green);
}

.giveawayImage{
    height: 150px;
    background-color: white;
    border-radius: 12px
}

.cardText{
    font-size: 20px;
}

.numberOfCards{
    color: var(--orange)
}

.giveawayStepsContainer{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.formContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin: 10vh 0;
}

.form{
    width: 40vw;
    max-width: 500px;
    padding: 10vh 10vw;
}

@media (max-width: 600px){
    .giveawayInfoContainer{
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;
    }

    .formContainer{
        display: block;
    }

    .form{
        width: 100%;
        padding: 5vh 0;
    }
}