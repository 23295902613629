/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.title {
  color: var(--orange);
  font-size: 60px;
  text-align: center;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: 5vw;
}
.warningCv {
  color: red;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}

.toTechnicalTestBtn {
  color: #000000;
  background-color: #ffad4a;
  padding: 8px 16px;
  border-radius: 12px;
}

.toTechnicalTestBtn:hover {
  color: #000000;
  background-color: #c77d22;
}

.CLEmail {
  color: var(--orange);
  margin-bottom: 20px;
  text-align: center;
}

.userEmail {
  word-wrap: break-word;
  color: var(--forest-green);
}

.infoIcon {
  height: 24px;
  cursor: pointer;
  margin-left: 15px;
}

.miniHeader {
  text-align: center;
}

.modalParagraph {
  margin-bottom: 20px;
}

.emailAccent {
  color: var(--orange);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.eventsDescriere {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width: 100%;
}


@media (max-width: 600px) {
  .userEmail{
    font-size: 7vw;
  }

  .cardsContainer {
    gap: 2rem;
  }

  .title {
    font-size: 40px;
  }

  .eventsDescriere {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
  }

  .containerCLCard {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}