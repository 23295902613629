.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    height: 260px;
    background-color: white;
    border-radius: 12px;
    margin-bottom: 1rem;
}

.imgContainer {
    display: flex;
    min-height: 135px;
    align-items: center;
    justify-content: center;
}

.cardImg {
    width: 80%;
}

.bars {
    display: none;
}

.detailsAndRulesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding-bottom: 0.5rem;
}

@media (max-width: 800px) {
    .cardContainer {
        align-items: flex-start;
        height: 175px;
    }

    .imgContainer {
        min-height: 80px;
        justify-content: space-around;
        font-size: 20px;
    }

    .cardImg {
        width: 60%;
    }

    .bars {
        display: block;
    }
}