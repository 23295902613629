.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: black;
  margin: 1rem;
  padding: 3rem;
  width: 97.69%;
  height: 97.69%;
  position: relative;
}
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
.formContainerBorder {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: conic-gradient(
    from var(--angle), #074a29,
    #399345,
    #89cb81,
    #074a29
  );
  margin: 5vh 0;
  border-radius: 1rem;
  animation: 3s spin linear infinite;
}
@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}
.formHeader {
  margin: 5vh 0;
  font-size: 20px;
}

.headerMain {
  font-size: 40px;
  margin-bottom: 6px;
}
