.membru {
  background-color: var(--forest-green);
  color: black;
  width: 13rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 0.5rem;
   
}

.close {
  font-size: 1.4rem;
  align-items: flex-end;
}

.close:hover {
  cursor: pointer;
  color: red;
}
