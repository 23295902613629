.cardContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.innerCardContainer{
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 265px;
    margin-bottom: 20px;
    border-radius: 1.8rem;
    border: 2px solid #074A29;
}

.aboutQuestContainer{
    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}