/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */


.adminContainer {
  width: 100%;
}

.mainpart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  padding-bottom: 3rem;
  flex-wrap: wrap;
}

.attendenceWrapper {
  display: flex;
  flex-direction: row;
  width: 37rem;
}

.hackathonTeamsWrapper {
  padding-bottom: 2rem;
  width: 37rem;

}

.hackathonStatisticsWrapper {}

.userTableWrapper {}

.userTableWrapper>div {
  width: 100%;
}



.dashboardEvents {
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}


.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;
  border-radius: 5%;
}

.hackathonTeamsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  justify-self: center;
  width: 100%;
  gap: 1rem;
}

.containerSaveCVBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveCVBtn {
  width: 12rem;
  height: 3rem;
  font-size: 22px;
  background-color: #1a9a32;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 724px) {
  .adminContainer {}

  .userTableWrapper {
    overflow-x: scroll;
  }

  .dashboardEvents{
    width: 90%;
  }
}