/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */


.hackathonContainter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  color: var(--orange);
  font-size: 60px;
  text-align: center;
}

.hackathonID {
  text-align: center;
  margin: 4rem 0 2rem 0;
}

.eventsDescriere {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width: 70%;
}

.userID {
  margin-left: 20px;
}

.infoIcon {
  height: 24px;
  cursor: pointer;
  margin-left: 15px;
}

.hackathonsDate {
  text-align: center;
  margin-bottom: 10px;
}

.dateText {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.4rem;
}

.modalParagraph {
  margin-bottom: 20px;
}

.eventsDescriereNoTeam {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width:100%;
}

.datesContainer {
  display: flex;
  justify-content: center;
  gap: 10vw;
  align-items: center;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  gap: 5vw;
}

.teamContainer {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  padding-bottom: 3rem;
}

.hackathonCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  height: 280px;
  background-color: white;
  border-radius: 12px;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.teamContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .cardsContainer {
    gap: 10vh;
    font-size: 18px;
    text-align: center;
  }
}
@media (max-width: 912px) {
  .teamContainer {
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 40px;
  }
  .eventsDescriere {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
  }
  .eventsDescriereNoTeam {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
  }
}
