.containerFormLanParty {
    width: 40vw;
    height: min-content;
    gap: 1rem;
    background-color: #0a0a0a;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #1A9A32;
    border-radius: 15px;
    padding: 2rem;
    filter: drop-shadow(0px 0px 40px #1a9a3167);
}

.formularFifa {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formLanParty {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonFormLanParty {
    border-radius: 10px;
    width: 7rem;
    height: 4vh;
    border: none;
    font-size: 1rem;
    font-weight: 700;
}

.inputFormLanParty {
    all: unset;
    border-radius: 1vh;
    background-color: #0a0a0a;
    width: 100%;
    height: 4vh;
    border: 2px solid #1A9A32;
}

.buttonRegFormLanParty {
    cursor: pointer;
    background-color: #141414;
    color: #1A9A32;
}

.buttonRegFormLanParty:hover {
    transition: all 0.3s ease-in-out;
    background-color: #1A9A32;
    color: #141414;
}

.buttonInsFormLanParty {
    cursor: pointer;
    background-color: #FFAD4A;
    color: #141414;
}

.buttonInsFormLanParty:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
}

.buttonInsFormLanPartyClosed {
    background-color: #141414;
    color: #4f3515;
}

.containerMidFormLanParty {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-bottom: 2rem;

}

.containerBtnFormLanParty {
    display: flex;
    gap: 2vw;
}

.containerAddMembru {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 1vh;
    background-color: #0A0A0A;
    height: 4.5vh;
    border: 2px solid #1A9A32;
}

.inputFormLanPartyAddMembru {
    all: unset;
    width: inherit;
    margin-left: 0.5rem;
}

.plusLanParty {
    margin: 0.5rem;
}

.plusLanParty:hover {
    cursor: pointer;
}

.listaNumeCoechipieri {
    font-size: small;
}

.imgLan {
    width: 200px;
    border-radius: 1rem;
    border-style: solid;
    margin: 1rem;
    border-width: 6px;
}

.buttonBack {
    all: unset;
    position: absolute;
    top:5%;
    left: 5%;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #1A9A32;
    padding: 0.5rem 1.1rem;
    border-radius: 50%;
}

.buttonBack:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #ffad4a;
}

h2 {
    padding-bottom: 2rem;
}

@media(max-width: 1270px) {
    .containerFormLanParty {
        width: 90vw;
        padding: 0rem 1rem 1rem 1rem;
    }

    .buttonFormLanParty {
        width: 8rem;
        height: 6vh;
    }

}