/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.title {
  color: var(--orange);
  font-size: 60px;
  text-align: center;
}

.eventsDescriere {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width: 50%;
}



.workshopDate {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.4rem;
}

.workshopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signedUp {
  height: 6rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
}
#u {
  font-size: 40px;
}

.workshopPageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;
}

@media (max-width: 912px) {
  .title {
    font-size: 40px;
  }
  .workshopPageContainer {
    flex-direction: column;
    gap: 0;
  }
  .eventsDescriere {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
  }
}
