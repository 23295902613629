.forgotContainer {
  display: flex;
  justify-content: center;

  align-items: center;
  min-height: 100vh;
}
.fieldContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.eyeIcon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
