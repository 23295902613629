* {
  margin: 0;
  padding: 0;
}
.chenarEchipe {
  background-color: rgba(0, 0, 0, 0.342);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.2rem solid var(--lime-green);
  border-radius: 5px;
  width: 30%;
  justify-content: flex-start;
  padding: 1rem;
  height: 36rem;
}
.titlu {
  font-size: 30px;
  margin: 1rem;
  font-weight: bold;
  text-align: center;
}

.text {
  color: black;
  background: var(--forest-green);
  margin: 0.15rem;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
}

.teamName {
  color: black;
  font-weight: bold;
  background-color: var(--forest-green);
  width: 100%;
  border-style: dashed;
  border-color: var(--lime-green);
  border-width: 0.2rem;
  border-radius: 0.2rem;
  font-size: 20px;
  padding: 0.1rem 0.2rem;
  word-break: break-all;
}

.teamNameWrapper {
  border-style: dashed;
  border-color: #ffad4a;
  padding: 12px;
}

.echipa ol li {
  background: var(--forest-green);
  width: 100%;
  border-radius: 0.1rem;
  min-height: 1.7rem;
}

.echipa ol {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 0 5%;
}

.continutEchipe {
  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #ffad4a transparent;
  padding: 0 0 0.5rem 0.5rem;
  width: 100%;
  gap: 18px;
}

.echipa {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 912px) {
  .chenarEchipe {
    width: 98%;
    padding: 8px;
    height: 38rem;
  }

  .continutEchipe {
    box-sizing: border-box;
  }
}
