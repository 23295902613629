.modalContainer{
    min-width: 280px;
    max-width: 30vw;
    max-height: 70vh;
    overflow-y: auto;
    background-color: #074A29;
    color: white;
    border: 2px solid white;
    border-radius: 1.5rem;
    padding: 20px;
}

.detailsHeader{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.detailsContent{
    padding: 3vh 0;
    text-align: justify;
    text-justify: inter-word;
}