/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.signUpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 10vw;
  z-index: 0;
}

.formFieldContainer {
  height: 90vh;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginSwitchPage {
  margin-top: 12px;
  width: 100%;
  text-align: center;
}

.containerCV {
  margin: 40px 0;
}

.GDPRContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.GDPRWord {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.inputError {
  height: 14px;
  font-size: 12px;
  color: red;
  margin-top: -15px;
  margin-bottom: 10px;
  text-align: start;
  text-indent: 13px;
}

#uploadCV {
  font-size: larger;
}

#uploadCV:hover {
  color: var(--pale-green);
  cursor: pointer;
}

.inactiveBtn {
  background: gray;
}

.inactiveBtn:hover {
  cursor: none;
  background: inherit !important;
}
