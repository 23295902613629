/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.location {
  font-size: 20px;
}

.title {
  color: var(--orange);
  font-size: 60px;
  text-align: center;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin-top: 5vh;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 5rem 0;
}

.signedUp {
  height: 1rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.eventsDescriere {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width: 100%;
}

@media (max-width: 912px) {
  .title {
    font-size: 40px;
  }
  .eventsDescriere {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
  }
}
