.filepond--file-action-button {
  cursor: pointer;
}

.filepond--root {
  width: 30vw;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: white;
  font-size: 0rem;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: black;

  border: 0px;
}

@media (max-width: 912px) {
  .filepond--root {
    width: 50vw;
  }
}
