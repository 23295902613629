:root {
  --dark-green: #074a29;
  --forest-green: #399345;
  --lime-green: #1a9a32;
  --pale-green: #b6e29f;
  --pistachio-green: #89cb81;
  --orange: #ffad4a;
  --black: #000000;
  --white: #ffffff;
}
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", system-ui;
  box-sizing: border-box !important;
}

html {
  min-height: 100vh;
}

body {
  box-sizing: border-box;
}

.mainContainer {
  min-height: 100vh;
  background: linear-gradient(190deg, #2a4a2e 0%, #080a09 30%);
  color: var(--orange);
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: lightgray;
}

::-webkit-scrollbar {
  max-width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(124, 122, 122);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
}

.doc-content * {
  margin: 1rem 0;
  word-break: keep-all;
}

.doc-content li {
  margin-left: 3rem;
}

.doc-content h1 {
  font-size: 22px;
}
