/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.forgotpassword {
  display:flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 18px;
}

.fieldContainer {
  position: relative;
  display: flex;
  align-items: end;
  gap: 10px;
}

.eyeIcon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginSwitchPage {
  margin-top: 12px;
  width: 100%;
  text-align: center;
}

