/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.containerQRPage{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QRPageButton{
    cursor: pointer;
    padding:1rem 2rem;
    width: 60%;
    border:none;
    border-radius:8%;
    font-family: inherit;
    background-color: var(--orange)
}

.scanner{
    width: 40vw;
    border-style: solid;
    border-color: #e50000;
}