/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.burgerMenu {
  padding: 2vh 2vw;
}

.burgerMenu img {
  cursor: pointer;
}

.eventsSidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  padding: 5vh 0;
}

.logoImage {
  align-self: center;
}

.eventsContainer {
  text-align: center;
  margin-bottom: 6vh;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.eventsButton {
  font-size: 18px;
  background-color: var(--black);
  border: 0;
  color: white;
  cursor: pointer;
}

.eventsButton:hover {
  color: red;
}

.pagesContainer {
  padding: 2vh 10vw;
  padding-bottom: 4vh;
}

@media (max-width: 600px) {
  .burgerMenu {
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: #080a09;
    border-bottom: 2px solid var(--orange);
  }

  .pagesContainer {
    padding: 6vh 6vw;
    padding-top: 3vh;
  }
}

@media only screen and (max-width: 912px) {


  .drawerSidebar {
    width: 100%;
  }
}
