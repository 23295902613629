.signedUp{
    height: 36.5px;
    display: flex;
    align-items: center;
}

.signedUpContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.checkIcon{
    height: 24px;
    border: 2px solid white;
    border-radius: 50%;
    padding: 1px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formItem{
    width: 100%;
}

.inputError{
    height: 14px;
    font-size: 12px;
    color: red;
    margin-bottom: 24px;
    text-align: end;
}

