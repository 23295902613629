.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: black;
  margin: 1rem;
  padding: 3rem 4rem;
  width: 95%;
  height: 95%;
  position: relative;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.loginContainerBorder {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: conic-gradient(from var(--angle),
      #074a29,
      #399345,
      #89cb81,
      #074a29);
  margin: 5vh 0;
  border-radius: 1rem;
  animation: 3s spin linear infinite;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 360deg;
  }
}

.loginHeader {
  margin: 5vh 0;
}

.headerMain {
  font-size: 26px;
  margin-bottom: 6px;
}

@media only screen and (max-width: 912px) {
  .loginContainerBorder {
    width: 95vw;
    padding: 0.4rem;
  }
}