.eventContainer {
  padding: 8px 0;
}

.eventOnClick {
  border-radius: 0.3rem;
  background-image: linear-gradient(to right, #000000, #399345);
}
a {
  color: var(--white);
}
a:hover {
  color: var(--orange);
  font-weight: bold;
  transition: 0.2s ease;
  
}
