.hackathonTeamContainer{
    background-color: #1a9a32;
    color: #ecf7ee;
    border-radius: 2rem ;
    display: flex;
    width: 15rem;
    flex-direction: column;
    margin: 1rem;
}

.hackathonTeamContainer > h3{
    padding: 1rem;
}

.hackathonTeamMemberList{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
} 

.hackathonTeamPill{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hackathonTeamCaptain{
    border-style: dashed;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.plusLanParty:hover {
    cursor: pointer;
}