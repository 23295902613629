.attendanceContainer{
    background-color: #ffffff;
    border-radius: 2rem ;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 0 10px;
    color: black;
    font-weight: bold;
}