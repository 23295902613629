.eventsDescriere {
  color: var(--pistachio-green);
  text-align: justify;
  font-size: 1.5rem;
  width: 50%;
}
@media (max-width: 912px) {
    .eventsDescriere {
        flex-direction: column;
        gap: 0;
    }
}