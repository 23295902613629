.passwordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: black;
  margin: 1rem;
  padding: 3rem 2rem 3rem 2rem;
  width: 95%;
  height: 95%;
  position: relative;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
.passwordContainerBorder {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: conic-gradient(
    from var(--angle),
    #074a29,
    #399345,
    #89cb81,
    #074a29
  );
  margin: 5vh 0;
  border-radius: 1rem;
  animation: 3s spin linear infinite;
}
@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

.title {
  font-size: 35px;
  color: White;
  font-weight: bold;
}
.passwordElements {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8rem;
  margin-bottom: 3rem;
}
.goBack {
  display: flex;
  color: white;
  align-self: flex-start;
  font-size: 30px;
}
.goBack:hover {
  cursor: pointer;
}
