/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

.profileContainer {
  display: flex;
  margin-top: 5vh;
}

.profileCentralContainer {
  display: flex;
  flex-grow: 3;
  justify-content: center;
}

#uploadCV {
  font-weight: bold;
}

#uploadCV:hover {
  color: var(--pale-green);
  cursor: pointer;
}

.profileFirstCentralContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.changeCV {
  min-width: 250px;
  width: 30vw;
  max-width: 800px;
}

.profileCareerLaunchTimer {
  border-radius: 0.2rem;
  background-color: #399345;
  min-width: 250px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 15px 0;
  justify-content: center;
  height: 26rem !important;
}

.lowerSectionContainer {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.eventsContainer {
  width: 100%;
}
.eventContainer {
  height: 5rem;
  width: 100%;
  border-radius: 0.2rem;
  background-color: #399345;
  color: black;
  font-weight: bold;
  margin-bottom: 2rem;
}
.eventBorderContainer {
  height: 5rem;
  width: 100%;
  border-color: #399345;
  border-radius: 0.2rem;
  border-width: medium;
  border-style: solid;
  color: #399345;
  font-weight: bold;
  margin-bottom: 2rem;
}
.profileRightContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 26rem;
  width: 22rem;
  border-radius: 10px;
  background-color: #399345;
  margin-left: 2rem;
  justify-content: center;
  align-items: center;
}

.contentEvent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.contentEvent button {
  padding: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.paragSpan {
  width: 75%;
}
.paragSpan p {
  margin-left: 2rem;
}
.buttonSpan {
  width: 25%;
}
.eventsRightContainer {
  margin-top: 2rem;
  justify-content: center;
}
.eventRightContainer {
  height: 5rem;
  width: 16rem;
  background-color: greenyellow;
  margin-bottom: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventsRightContainer button {
  cursor: pointer;
}

.containerSaveCVBtn {
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 1rem;
}
.containerSaveCVBtn:hover {
  color: var(--pale-green);
}

.containerSaveCVBtn > button {
  padding: 1rem;
  border-radius: 8%;
  background-color: var(--orange);
}

.btnDescarcaCV {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 30vw;
  height: 5rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 7px;
  border: 0px;
}

.btnNuDescarcaCV {
  color: rgb(255, 255, 255);
  background-color: gray;
  width: 30vw;
  height: 5rem;
  font-size: 16px;
  font-weight: bold;
  border-radius: 7px;
  border: 0px;
}

.btnDescarcaCV:hover {
  color: var(--pale-green);
}

.wrapperBtnDownloadCV {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  border-style: dashed;
  border-color: #ffad4a;
  height: 100%;
  width: 100%;
}

.containerBtnDownloadCV {
  background-color: #399345;
  padding: 1px;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26rem !important;
  padding: 1.4rem;
  width: 100%;
}

.containerDownload {
  display: flex;
  align-items: center;
  background-color: black;
}

@media (max-width: 912px) {
  .profileRightContainer {
    margin-left: 0rem;
  }

  .profileFirstCentralContainer {
    display: flex;
    flex-direction: column;
  }
  .profileContainer {
    display: block;
  }

  .profileFirstCentralContainer {
    width: 100%;
  }

  .profileFirstCentralContainer > * {
    min-width: 100%;
  }

  .changeCV {
    min-width: 100%;
  }

  .containerBtnDownloadCV {
    padding: 0.5rem;
  }

  .btnDescarcaCV,
  .btnNuDescarcaCV,
  .changeCV {
    width: 70vw !important;
  }
  .eventContainer {
    height: 8rem;
    
  }
  .eventBorderContainer {
    height: 8rem;
  }
  .contentEvent p {
    font-size: 20px;
  }
  .wrapperBtnDownloadCV {
    height: 80%;
    width: 92%;
  }
}

@media screen and (max-width: 1270px) {
  .lowerSectionContainer {
    width: 100%;
  }
  .profileCentralContainer {
    flex-wrap: wrap;
  }
}
