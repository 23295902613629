.contur {
  padding: 0.5rem 2.5rem;
  border: 0.2rem solid var(--lime-green);
  border-radius: 0.2rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titlu {
  margin-top: 2rem;
  margin-left: 0.8rem;
  margin-bottom: 0.2rem;
  word-break: break-all;
  padding: 0;
}

.display {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nedisplay {
  display: none;
}
.addMember {
  color: white;
}
