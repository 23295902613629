.modalContainer {
  min-width: 280px;
  width: 30vw;
  font-size: 23px;
  max-width: 450px;
  max-height: 70vh;
  overflow-y: auto;
  background-color: #074a28d8;
  color: white;
  border: 4px solid #89cb81;
  border-radius: 1.5rem;
  padding: 20px;
  scrollbar-color: #89cb81 transparent;
  scrollbar-gutter: stable both-edges;
}
.modalContainer::-webkit-scrollbar{
    margin-top: 10px;
}

.modalContainer-webkit-scrollbar-track-piece {
  color: black;
  border: solid 1px black;
}

.modalHeader {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 912px) {
  .modalContainer {
    width: 85vw;
    font-size: large;
  }
}