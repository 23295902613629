/* Reguli de denumire ale claselor: */
/* Clasele vor avea numele incepand cu sectiunea paginii iar apoi elementul*/
/* Puteti separa clasele daca sunt multe elemente de un anumit tip care trebuie modificare, de exemplu pentru butoane*/
/* Clasele vor fi denumite in engleza*/
/* Exemplu:  achievements-container */

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

.lanContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

button{
  margin: 0.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 5rem 0;
}

.signedUp {
  height: 1rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem;
}

.descriere {
  width: 40%;
  text-align: justify;
  text-justify: inter-word;
  padding: 2rem;
  font-size: 23px;
  color: var(--pistachio-green);
}

.title {
  color: #ffad4a;
  font-size: 60px;
  text-align: center;
}

.buttonFormLanParty {
  border-radius: 10px;
  width: 7rem;
  height: 4vh;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  background-color: #399345;
  margin-top: 2rem;
  cursor: pointer;
}

.buttonFormLanParty:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
  background-color: #1a9a32;
  color: #141414;
}

.containerEchipaLan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1270px) {
  .lanContainer {
    flex-direction: column;
    align-items: center;
  }

  .descriere {
    flex-direction: column;
    width: 100%;
    gap: 0;
    font-size: 1rem;
    padding: 0 0 2rem 0;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 5rem 0;
  }
  .title {
    font-size: 40px;
    padding-bottom: 1rem;
  }
}
