.stepContainer{
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.step{
    display: inline-block;
    width: 34px;
    height: 34px;
    padding: 4px;
    color: white;
    background-color: #399345;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.link{
    text-decoration: underline;
    margin-left: 0.6rem;
}