.formContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 20px;
}
.input {
  width: 100%;
  padding-left: 10px;
  font-size: 25px;
  margin: 5px;
  color: white;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input[type="number"] {
  -moz-appearance: textfield;
  /* sugi pula */
}
